<template>
  <v-fade-transition appear>
    <v-overlay :value="isIdle" z-index="1000" color="primary darken-2">
      <v-container class="fill-height">
        <v-row align="center" justify="center">
          <v-fade-transition mode="out-in">
            <div :key="countdownValue" class="rai-data text-h1">
              <span v-show="countdownValue > 0">{{ countdownValue }}</span>
              <v-icon v-show="countdownValue === 0" size="80">$lock</v-icon>
            </div>
          </v-fade-transition>
        </v-row>
      </v-container>
    </v-overlay>
  </v-fade-transition>
</template>

<script>
import store from "@/store";
import idleTimeout from "idle-timeout";
import { computed, onUnmounted, ref, watch } from "vue";

export default {
  name: "EmployeeIdle",
  props: {
    countdown: {
      type: Number,
      default: 3,
    },
  },
  setup(props, { emit }) {
    /** @type {import("vue").Ref<import("idle-timeout/dist/IdleTimeout").default>} */
    const idleTimer = ref(null);

    /** @type {import("vue").Ref<NodeJS.Timer>} */
    const idleCountdown = ref(null);

    /** @type {import("vue").Ref<Number>} */
    const countdownValue = ref(props.countdown);

    const isIdle = computed(() => idleTimer.value?.isIdle === true);

    watch(isIdle, (value) => {
      value ? startIdleCountdown() : stopIdleCountdown();
    });

    startIdleTimer();

    onUnmounted(() => {
      stopIdleCountdown();
      stopIdleTimer();
    });

    function startIdleCountdown() {
      addResetIdleListeners();
      idleCountdown.value = setInterval(() => {
        if (countdownValue.value < 1) {
          stopIdleCountdown();
          emit("done");
        }

        countdownValue.value -= 1;
      }, 1000);
    }

    function stopIdleCountdown() {
      removeResetIdleListeners();
      countdownValue.value = props.countdown;
      clearInterval(idleCountdown.value);
      idleCountdown.value = null;
    }

    function startIdleTimer() {
      idleTimer.value = idleTimeout(() => {}, {
        timeout: 1000 * (store.state.ui.idle || 90),
        loop: false,
      });
    }

    function stopIdleTimer() {
      idleTimer.value?.destroy();
      idleTimer.value = null;
    }

    function addResetIdleListeners() {
      idleTimer.value.eventNames.forEach((eventName) => {
        window.addEventListener(eventName, resetIdleTimer);
      });
    }

    function removeResetIdleListeners() {
      idleTimer.value.eventNames.forEach((eventName) => {
        window.removeEventListener(eventName, resetIdleTimer);
      });
    }

    function resetIdleTimer() {
      idleTimer.value?.reset();
      emit("reset");
    }

    return { isIdle, countdownValue };
  },
};
</script>
